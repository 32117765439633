import React from 'react';
import './policies.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
            <div className="privacy-policy-card">
                <h2>Privacy Policy for Anonymous Dating Website</h2>
                <p>At Huppy People (Website/App) we are committed to protecting your privacy and ensuring the confidentiality of any personal information you provide to us. This Privacy Policy outlines how we collect, use, disclose, and manage your information when you use our anonymous dating website.</p>
                
                <h3>1. Information We Collect</h3>
                <ul>
                    <li><strong>Registration Information:</strong> When you sign up for our service, we collect certain personal information such as your username, email address, age, gender, and preferences. However, we do not require you to provide your real name or any other identifiable information.</li>
                    <li><strong>User-generated Content:</strong> Any content you create or upload to our platform, such as profile information, photos, and messages, may be collected and stored.</li>
                    <li><strong>Usage Data:</strong> We may collect information about how you interact with our website, including your IP address, browser type, device information, and pages visited.</li>
                    <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to enhance your user experience and collect information about your browsing activities on our website.</li>
                </ul>
                
                <h3>2. How We Use Your Information</h3>
                <ul>
                    <li><strong>To Provide and Personalize Our Services:</strong> We use your information to create and manage your account, facilitate connections with other users, and personalize your experience on our website.</li>
                    <li><strong>To Improve Our Services:</strong> We analyze usage data and feedback to improve the functionality and features of our website and develop new services.</li>
                    <li><strong>To Communicate with You:</strong> We may send you administrative messages, service announcements, and other communications related to your use of our website.</li>
                    <li><strong>To Ensure Security:</strong> We use your information to detect and prevent fraud, abuse, and unauthorized access to our website.</li>
                </ul>
                
                <h3>3. How We Share Your Information</h3>
                <ul>
                    <li><strong>With Other Users:</strong> Certain information you provide may be visible to other users of our website, depending on your privacy settings and the features you use.</li>
                    <li><strong>With Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our website, such as hosting providers, payment processors, and analytics services.</li>
                    <li><strong>For Legal Purposes:</strong> We may disclose your information to comply with legal obligations, enforce our terms of service, or respond to lawful requests from government authorities.</li>
                </ul>
                
                <h3>4. Data Retention</h3>
                <p>We retain your information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
                
                <h3>5. Your Privacy Choices</h3>
                <p>You have the right to access, update, and delete your personal information at any time by logging into your account settings. You can also manage your communication preferences and privacy settings.</p>
                
                <h3>6. Children’s Privacy</h3>
                <p>Our website is not intended for use by children under the age of 21 and we do not knowingly collect personal information from minors.</p>
                
                <h3>7. Changes to This Privacy Policy</h3>
                <p>We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. We will notify you of any material changes by posting the updated policy on our website or sending you a notification.</p>
                
                <h3>8. Contact Us</h3>
                <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <a href="mailto:assist@huppypeople.com">assist@huppypeople.com</a></p>
                
                <p>By using our website, you consent to the collection, use, and disclosure of your information as described in this Privacy Policy.</p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
