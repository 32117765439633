import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import './profile.css';
import Notification from '../../components/notification/NotificationPopover';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import Cookies from 'js-cookie';
import {
  ageGroupOptions,
  complexionOptions,
  bodyShapeOptions,
  heightOptions,
  countryOptions,
  sexualityOptions,
  reasonOptions,
  ethnicityOptions,
  religionOptions,
  countiesWithConstituencies
} from '../../components/searchandform/formOptions';

const backend_url = process.env.REACT_APP_BACKEND_URL;

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({});
  const [constituencyOptions, setConstituencyOptions] = useState([]);
  const [showCountyFields, setShowCountyFields] = useState(false);
  const { isAuthenticated, currentUserId, currentUserEmail, socket, storedNotifications } = useAuth();
  const token = Cookies.get('token');

  useEffect(() => {
    if (isAuthenticated && currentUserId) {
      fetchUserData(currentUserId);
    }
  }, [currentUserId, isAuthenticated]);

  const fetchUserData = (id) => {
    fetch(`${backend_url}/profile/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => {
        if (response.status === 401) {
          return <Navigate to="/login" />;
        }
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        return response.json();
      })
      .then(data => {
        setUserData(data);
        setFormData({ ...data.user, ...data.profile });
        if (data.profile.country_of_residence === 'Kenya') {
          setShowCountyFields(true);
          const selectedCounty = countiesWithConstituencies.find(county => county.county === data.profile.county_of_residence);
          setConstituencyOptions(selectedCounty ? selectedCounty.constituencies : []);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, whatsapp: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'country_of_residence') {
      setShowCountyFields(value === 'Kenya');
      if (value !== 'Kenya') {
        setFormData((prevState) => ({ ...prevState, county_of_residence: '', constituency: '' }));
        setConstituencyOptions([]);
      }
    }

    if (name === 'county_of_residence') {
      const selectedCounty = countiesWithConstituencies.find(county => county.county === value);
      setConstituencyOptions(selectedCounty ? selectedCounty.constituencies : []);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${backend_url}/profile/${currentUserId}/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ profile: formData }),
    })
      .then(response => {
        if (response.status === 401) {
          return <Navigate to="/login" />;
        }
        if (!response.ok) {
          throw new Error('Failed to update profile');
        }
        return response.json();
      })
      .then(data => {
        setIsEditing(false);
        fetchUserData(currentUserId);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };
  console.log(storedNotifications, 'storedNotifications');


  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="profile-container">
      {userData ? (
        <>
          <div className="profile-header">
            <div className="profile-info">
              <img src={userData.profile.image_url} alt="Profile" className="profile-img" />
              <div className='memberprof'>
                <h2>{userData.user.first_name} {userData.user.last_name}</h2>
                <p><strong>{userData.profile.profession}</strong></p>
                <p><strong>{userData.user.gender}</strong></p>
                <button className="edit-profile-btn" onClick={handleEditClick}>Edit Profile</button>
              </div>
            </div>
          </div>
          {isEditing ? (
            <form className="profile-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Email</label>
                <input name="email" type="email" value={formData.email} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label>WhatsApp</label>
                <div className="form-group">
                  <PhoneInput
                    country={'ke'}
                    value={formData.whatsapp}
                    onChange={handlePhoneChange}
                    inputProps={{
                      name: 'whatsapp',
                      required: true,
                      autoFocus: true
                    }}
                    containerStyle={{ width: '100%' }}
                    inputStyle={{ width: '90%', borderRadius: '5px', marginLeft: '35px' }}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Complexion</label>
                <select name="complexion" value={formData.complexion} onChange={handleChange} required>
                  {complexionOptions[userData.user.gender].map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Body Shape</label>
                <select name="body_shape" value={formData.body_shape} onChange={handleChange} required>
                  {bodyShapeOptions[userData.user.gender].map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Height</label>
                <select name="height" value={formData.height} onChange={handleChange} required>
                  {heightOptions[userData.user.gender].map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Age Group</label>
                <select name="age_group" value={formData.age_group} onChange={handleChange} required>
                  {ageGroupOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Profession</label>
                <input name="profession" value={formData.profession} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label>Country of Residence</label>
                <select name="country_of_residence" value={formData.country_of_residence} onChange={handleChange} required>
                  {countryOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              {showCountyFields && (
                <>
                  <div className="form-group">
                    <label>County of Residence</label>
                    <select name="county_of_residence" value={formData.county_of_residence} onChange={handleChange} required>
                      {countiesWithConstituencies.map(({ county }) => (
                        <option key={county} value={county}>{county}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Constituency</label>
                    <select name="constituency" value={formData.constituency} onChange={handleChange} required>
                      {constituencyOptions.map(option => (
                        <option key={option} value={option}>{option}</option>
                      ))}
                    </select>
                  </div>
                </>
              )}
              <div className="form-group">
                <label>Sexuality</label>
                <select name="sexuality" value={formData.sexuality} onChange={handleChange} required>
                  {sexualityOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Reason</label>
                <select name="reason" value={formData.reason} onChange={handleChange} required>
                  {reasonOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Ethnicity</label>
                <select name="ethnicity" value={formData.ethnicity} onChange={handleChange} required>
                  {ethnicityOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Religion</label>
                <select name="religion" value={formData.religion} onChange={handleChange} required>
                  {religionOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Turn Off</label>
                <textarea name="turnoff" value={formData.turnoff} className='modern-textarea' onChange={handleChange} />
              </div>
              <div className="form-group">
                <label>Education</label>
                <textarea name="education" value={formData.education} className='modern-textarea' onChange={handleChange} />
              </div>
              <div className="form-group">
                <label>HIV Status</label>
                <div className="radio-group">
                  <label>
                    <input
                      type="radio"
                      name="hiv_status"
                      value="positive"
                      checked={formData.hiv_status === 'positive'}
                      onChange={handleChange}
                    />
                    Positive
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="hiv_status"
                      value="negative"
                      checked={formData.hiv_status === 'negative'}
                      onChange={handleChange}
                    />
                    Negative
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label>Dreadlock</label>
                <div className="radio-group">
                  <label>
                    <input
                      type="radio"
                      name="dreadlock"
                      value="yes"
                      checked={formData.dreadlock === 'yes'}
                      onChange={handleChange}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="dreadlock"
                      value="no"
                      checked={formData.dreadlock === 'no'}
                      onChange={handleChange}
                    />
                    No
                  </label>
                </div>
              </div>
              <button type="submit" className='edit-profile-btn'>Update Profile</button>
            </form>
          ) : (
            <div className='profile'>
              <div className="profile-details">
                <div className="profile-details-item"><strong>Email:</strong> {userData.user.email}</div>
                <div className="profile-details-item"><strong>WhatsApp No:</strong> {userData.user.whatsapp}</div>
                <div className="profile-details-item"><strong>Complexion:</strong> {userData.profile.complexion}</div>
                <div className="profile-details-item"><strong>Body Shape:</strong> {userData.profile.body_shape}</div>
                <div className="profile-details-item"><strong>Height:</strong> {userData.profile.height}</div>
                <div className="profile-details-item"><strong>Age Group:</strong> {userData.profile.age_group}</div>
                <div className="profile-details-item"><strong>Profession:</strong> {userData.profile.profession}</div>
                <div className="profile-details-item"><strong>Country of Residence:</strong> {userData.profile.country_of_residence}</div>
                {userData.profile.country_of_residence === 'Kenya' && (
                  <>
                    <div className="profile-details-item">
                      <strong>County of Residence:</strong> {userData.profile.county_of_residence}
                    </div>
                    <div className="profile-details-item">
                      <strong>Constituency:</strong> {userData.profile.constituency}
                    </div>
                  </>
                )}
                <div className="profile-details-item"><strong>Sexuality:</strong> {userData.profile.sexuality}</div>
                <div className="profile-details-item"><strong>Ethnicity:</strong> {userData.profile.ethnicity}</div>
                <div className="profile-details-item"><strong>Religion:</strong> {userData.profile.religion}</div>
                <div className="profile-details-item"><strong>Turn Off:</strong> {userData.profile.turnoff}</div>
                <div className="profile-details-item"><strong>Number of Kids:</strong> {userData.profile.number_of_kids}</div>
                <div className="profile-details-item"><strong>Education:</strong> {userData.profile.education}</div>
                <div className="profile-details-item"><strong>HIV Status:</strong> {userData.profile.hiv_status}</div>
                <div className="profile-details-item"><strong>Dreadlock:</strong> {userData.profile.dreadlock}</div>
              </div>
              <div className="profile-details-item reason-for-searching"><strong>Reason:</strong> {userData.profile.reason}</div>
            </div>
          )}
          <div className="approval-section">
            <Notification socket={socket} storedNotifications={storedNotifications} />
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Profile;
