import React, { useState } from 'react';
import { Container } from "react-bootstrap";
import '../../pages/homepages/homepages.css'

function Search({ onSearch }) {
  const [query, setQuery] = useState('');

  const handlesearch = (event) => {
    const getSearch = event.target.value;
    setQuery(getSearch);
    onSearch(getSearch);
  };

  return (
    <React.Fragment>
      <Container>
        <div className='search-container'>
          <input
            type="text"
            name='name'
            value={query}
            className="search-input"
            onChange={(e) => handlesearch(e)}
            placeholder='Search by Name'
          />
        </div>
      </Container>
    </React.Fragment>
  );
}

export default Search;
