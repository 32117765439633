import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './signup.css';
import DisclaimerModal from '../../components/Policies/Disclaimer';
import {
    genderOptions, ageGroupOptions, complexionOptions, bodyShapeOptions,
    heightOptions, countryOptions, sexualityOptions, reasonOptions,
    ethnicityOptions, religionOptions, countiesWithConstituencies
} from '../../components/searchandform/formOptions';

const backend_url = process.env.REACT_APP_BACKEND_URL;

const Signup = () => {
    const [passwordError, setPasswordError] = useState('');
    const [constituencyOptions, setConstituencyOptions] = useState([]);
    const [showCountyFields, setShowCountyFields] = useState(false);
    const [phoneError, setPhoneError] = useState('');
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        whatsapp: '',
        gender: '',
        complexion: '',
        bodyShape: '',
        height: '',
        religion: '',
        profession: '',
        countryOfResidence: '',
        countyOfResidence: '',
        dreadlock: '',
        ageGroup: '',
        hivStatus: '',
        sexuality: '',
        reason: '',
        password: '',
        confirmPassword: '',
        education: '',
        imageUrl: '',
        ethnicity: '',
        constituency: '',
        turnOff: '',
        numberOfKids: 0,
    });
    const [showPassword, setShowPassword] = useState(false);
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const generateImageUrl = (first_name, last_name) => {
        const baseUrl = 'https://ui-avatars.com/api/?';
        return `${baseUrl}name=${first_name}+${last_name}.png`;
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (name === 'countryOfResidence') {
            setShowCountyFields(value === 'Kenya');
            if (value !== 'Kenya') {
                setFormData({ ...formData, countyOfResidence: '', constituency: '' });
                setConstituencyOptions([]);
            }
        }

        if (name === 'countyOfResidence') {
            const selectedCounty = countiesWithConstituencies.find(county => county.county === value);
            setConstituencyOptions(selectedCounty ? selectedCounty.constituencies : []);
        }

        if (name === 'confirmPassword') {
            setPasswordError(formData.password !== value ? 'Passwords do not match' : '');
        }

        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleGenderOrBodyShapeChange = (e) => {
        const { name, value } = e.target;
        const newFormData = { ...formData, [name]: value };

        if (newFormData.gender && newFormData.bodyShape) {
            newFormData.imageUrl = generateImageUrl(newFormData.firstName, newFormData.lastName);
        }

        setFormData(newFormData);
    };




    const handlePhoneChange = (value) => {
        setFormData({ ...formData, whatsapp: value });
        checkPhone(value);
    };

    const checkPhone = (value) => {
        const phoneDigits = value.replace(/\D/g, '');
        const countryCodeLength = value.startsWith('+') ? value.indexOf(' ') : 0;
        const digitsAfterCountryCode = phoneDigits.length - countryCodeLength;

        if (digitsAfterCountryCode < 6) {
            setPhoneError('Phone number must have at least 6 digits after the country code.');
        } else {
            setPhoneError('');
        }
    };
    const handleModalAccept = () => {
        setIsModalOpen(false);
        handleSubmit();
    };

    const handleSubmit = (e) => {
        if (e) e.preventDefault();
        if (formData.password !== formData.confirmPassword) {
            setPasswordError('Passwords do not match');
            return;
        }
        if (phoneError) {
            return;
        }
        fetch(`${backend_url}/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
        .then(response => response.json())
        .then(data => {
            if (data.message === 'User registered successfully') {
                setRegistrationSuccess(true);
                setTimeout(() => navigate('/login'), 5000);
            } else {
                setMessage(data.message);
                console.error('Error:', data.message);
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleSignUpClick = (e) => {
        e.preventDefault();
        setIsModalOpen(true);
    };

    if (registrationSuccess) {
        return (
            <div className="registration-success">
                <h2>Registration Successful!</h2>
                <p>Verification email sent. Please verify your email to login.</p>
            </div>
        );

    }

    return (
        <div>
            <form onSubmit={handleSignUpClick} className="registration-form">
                <h2>Create Your Account</h2>
                <p>Hey there! Ready to join the party? We just need a few details from you to get started. Let's do this!</p>
                <div className="form-group-inline">
                    <div className="form-group">
                        <input name="firstName" placeholder="First Name" onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <input name="lastName" placeholder="Last Name" onChange={handleChange} required />
                    </div>
                </div>
                <div className="form-group-inline">
                    <div className="form-group">
                        <input name="email" type="email" placeholder="Email" onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                    <PhoneInput
                            country={'ke'}
                            value={formData.whatsapp}
                            onChange={handlePhoneChange}
                            inputProps={{
                                name: 'whatsapp',
                                required: true,
                                autoFocus: true
                            }}
                            containerStyle={{ width: '100%' }}
                            inputStyle={{ width: '100%' }}
                        />
                        {phoneError && <span className="error" style={{ color: 'red' }}>{phoneError}</span>}
                    </div>
                </div>
                <div className="form-group-inline">
                    <div className="form-group">
                        <select name="gender" onChange={handleGenderOrBodyShapeChange} required>
                            <option value="">Gender</option>
                            {genderOptions.map(gender => (
                                <option key={gender} value={gender}>{gender}</option>
                            ))}
                        </select>
                    </div>    
                    <div className="form-group">
                        <input name="numberOfKids" type="number" placeholder="Number of Kids" onChange={handleChange} />
                    </div>
                </div>
                {formData.gender && (
                    <>
                        <div className="form-group">
                            <select name="complexion" onChange={handleChange} required className='selection'>
                                <option value="">Complexion</option>
                                {complexionOptions[formData.gender].map(complexion => (
                                    <option key={complexion} value={complexion}>{complexion}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <select name="bodyShape" onChange={handleGenderOrBodyShapeChange} required className='selection'>
                                <option value="">Body Shape</option>
                                {bodyShapeOptions[formData.gender].map(bodyShape => (
                                    <option key={bodyShape} value={bodyShape}>{bodyShape}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <select name="height" onChange={handleChange} required className='selection'>
                                <option value="">Height</option>
                                {heightOptions[formData.gender].map(height => (
                                    <option key={height} value={height}>{height}</option>
                                ))}
                            </select>
                        </div>
                    </>
                )}
                <div className="form-group-inline">
                    <div className="form-group">
                        <select name="religion" onChange={handleChange} required className='selection'>
                            <option value="">Religion</option>
                            {religionOptions.map(religion => (
                                <option key={religion} value={religion}>{religion}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <select name="ageGroup" onChange={handleChange} required className='selection'>
                            <option value="">Age Group</option>
                            {ageGroupOptions.map(ageGroup => (
                                <option key={ageGroup} value={ageGroup}>{ageGroup}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="form-group-inline">
                    <div className="form-group">
                        <input name="profession" placeholder="Profession" onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <select name="countryOfResidence" onChange={handleChange} required className='selection'>
                            <option value="">Country of Residence</option>
                            {countryOptions.map(country => (
                                <option key={country} value={country}>{country}</option>
                            ))}
                        </select>
                    </div>
                </div>
                {showCountyFields && (
                    <div className="form-group-inline">
                        <div className="form-group">
                            <select name="countyOfResidence" onChange={handleChange} required className='selection'>
                                <option value="">County of Residence</option>
                                {countiesWithConstituencies.map(({ county }) => (
                                    <option key={county} value={county}>{county}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <select name="constituency" onChange={handleChange} required className='selection'>
                                <option value="">Constituency</option>
                                {constituencyOptions.map(constituency => (
                                    <option key={constituency} value={constituency}>{constituency}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}
                <div className="form-group-inline">
                    <div className="form-group">
                        <label>Dreadlock</label>
                        <div className="radio-group">
                            <label>
                                <input type="radio" name="dreadlock" value="yes" onChange={handleChange} />
                                Yes
                            </label>
                            <label>
                                <input type="radio" name="dreadlock" value="no" onChange={handleChange} />
                                No
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <label style={{textAlign: 'left'}}>HIV Status</label>
                        <div className="radio-group">
                            <label>
                                <input type="radio" name="hivStatus" value="positive" onChange={handleChange} />
                                Positive
                            </label>
                            <label>
                                <input type="radio" name="hivStatus" value="negative" onChange={handleChange} />
                                Negative
                            </label>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <select name="sexuality" onChange={handleChange} required className='selection'>
                        <option value="">Sexuality</option>
                        {sexualityOptions.map(sexuality => (
                            <option key={sexuality} value={sexuality}>{sexuality}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <select name="reason" onChange={handleChange} required className='selection'>
                        <option value="">What are you looking for?</option>
                        {reasonOptions.map(reason => (
                            <option key={reason} value={reason}>{reason}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <select name="ethnicity" onChange={handleChange} required className='selection'>
                        <option value="">Ethnicity</option>
                        {ethnicityOptions.map(ethnicity => (
                            <option key={ethnicity} value={ethnicity}>{ethnicity}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group-inline">
                    <div className="form-group password-group">
                        <input
                            name="password"
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            onChange={handleChange}
                            required
                        />
                        <span className="toggle-password" onClick={toggleShowPassword}>
                            <i className={showPassword ? "fas fa-eye-slash black" : "fas fa-eye"} style={{ color: 'black' , marginTop: '10px'}}></i>   
                        </span>
                    </div>
                    <div className="form-group password-group">
                        <input
                            name="confirmPassword"
                            type={showPassword ? "text" : "password"}
                            placeholder="Confirm Password"
                            onChange={handleChange}
                            required
                        />
                        <span className="toggle-password" onClick={toggleShowPassword}>
                            <i className={showPassword ? "fas fa-eye-slash black" : "fas fa-eye"} style={{ color: 'black' , marginTop: '10px'}}></i>   
                        </span>
                    </div>
                </div>
                {passwordError && <span className="error" style={{ color: 'red' }}>{passwordError}</span>}
                <div className="form-group-inline">
                    <div className="form-group">
                        <textarea name="turnOff" placeholder="Turn off" className='modern-textarea' onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <textarea name="education" placeholder="Educational Level" className='modern-textarea' onChange={handleChange} />
                    </div>
                </div>
                <div className=" form-group  agreement">
                    <input type="checkbox" required />
                    <div style={{display: 'flex',  alignItems: 'center', justifyContent: 'space-between'}}>
                        I have read and agree to the<a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer"> Terms and Conditions</a>and the <a href="/privacy-policy" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>
                    </div>
                        
                </div>
                <button type="submit">Sign Up</button>
                <div>
                    <a href="/login">Already have an account? Login</a>
                </div>
                {message && <div className="registration-error">{message}</div>}

            </form>
            <DisclaimerModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onAccept={handleModalAccept}
            />
        </div>
    );
};

export default Signup;
