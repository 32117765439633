// src/components/Header.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import Notification from './notifi'; // Adjust path if necessary
import './header.css';
import logo from '../../assets/logs.png';

const Header = ({ socket }) => {
  const { isAuthenticated, logout, userGender} = useAuth();
  const navigate = useNavigate();
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);



  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleHomeClick = () => {
    if (isAuthenticated) {
      if (userGender === 'Male') {
        navigate('/maleHomepage');
      } else if (userGender === 'Female') {
        navigate('/femaleHomepage');
      }
    } else {
      navigate('/login');
    }
  };

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  return (
    <header className="header">
      <div className="logo">
        <Link to="/"><img src={logo} alt="logo" className="logo-image" /></Link>
      </div>
      <nav className="nav">
        <button onClick={handleHomeClick} className="nav-button">Home</button>
        {isAuthenticated ? (
          <>
            <button onClick={handleLogout} className="nav-button">Logout</button>
            <Link to="/myprofile" className="nav-button">Profile</Link>
          </>
        ) : (
          <>
            <Link to="/signup" className="nav-button">Register</Link>
            <Link to="/login" className="nav-button">Login</Link>
          </>
        )}
        <div className="notification-icon" >
          <span className="notification-count">{notifications.length}</span>
          🔔
        </div>
        {showNotifications && <Notification socket={socket}/>}
      </nav>
    </header>
  );
};

export default Header;
