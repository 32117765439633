// src/App.js
import React from 'react';
import { BrowserRouter , Route, Routes,  } from 'react-router-dom';
import Footer from './components/footer/footer';
import Landingpage from './pages/LandingPage/Landingpage';
import Signup from "./pages/Signuppage/signup";
import Login from './pages/Loginpage/login';
import Profile from './pages/Profilepage/profile';
import MaleHomePage from './pages/homepages/maleHomepage';
import FemaleHomePage from './pages/homepages/femaleHomepage';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Memberpage from './pages/memberpage/memberpage';
import Header from './components/header/header';
import ForgotPassword from './components/forgotpassword/ForgotPassword';
import ResetPassword from './components/forgotpassword/RessetPassword';
import './App.css'
import ResendConfirmation from './pages/Loginpage/ResendConfirmation';
import PrivacyPolicy from './components/Policies/Privacy';
import TermsAndConditions from './components/Policies/Termsandconditions';
const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Login />;
};

const App = () => {
  return (
      <BrowserRouter>
        <AuthProvider>
        <Header />
        <Routes>
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/myprofile" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path="/maleHomepage" element={<PrivateRoute><MaleHomePage /></PrivateRoute>} />
          <Route path="/femaleHomepage" element={<PrivateRoute><FemaleHomePage /></PrivateRoute>} />
          <Route path="/member/:userId" element={<PrivateRoute><Memberpage /></PrivateRoute>} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset_password/:token" element={<ResetPassword />} />
          <Route path="/resend-confirmation" element={<ResendConfirmation />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/" element={<Landingpage />} />
        </Routes>
        <Footer />
        </AuthProvider>
      </BrowserRouter>
  );
};

export default App;
