import React from 'react';
import './policies.css';

const TermsAndConditions = () => {
    return (
        <div className="terms-conditions-container">
            <div className="terms-conditions-card">
                <h2>Terms and Conditions of Use</h2>
                <p>Welcome to Huppy People (Website/App). Please read these terms and conditions carefully before using our services.</p>
                
                <h3>1. Acceptance of Terms</h3>
                <p>By accessing or using our website and services, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, you may not use our services.</p>
                
                <h3>2. Eligibility</h3>
                <p>You must be at least 21 years old to use our services. By using our services, you warrant that you are at least 21 years old.</p>
                
                <h3>3. Description of Service</h3>
                <p>Huppy People (Website/App) provides an anonymous platform for individuals to connect and communicate with each other for dating and socializing purposes.</p>
                
                <h3>4. User Conduct</h3>
                <p>By using our services, you agree to:</p>
                <ul>
                    <li>Provide accurate and truthful information when creating your profile.</li>
                    <li>Respect the privacy of other users.</li>
                    <li>Not engage in any unlawful or harmful activities.</li>
                    <li>Not harass, abuse, or threaten other users.</li>
                    <li>Not use our services for commercial purposes or solicitations.</li>
                </ul>
                
                <h3>5. Privacy</h3>
                <p>We respect your privacy and are committed to protecting your personal information. Please review our Privacy Policy to understand how we collect, use, and disclose your information.</p>
                
                <h3>6. User Content</h3>
                <p>You are solely responsible for any content you post or transmit through our services. By posting content, you grant Huppy People a non-exclusive, royalty-free, perpetual, and worldwide license to use, modify, and reproduce your content.</p>
                
                <h3>7. Proprietary Rights</h3>
                <p>All content on our website, including but not limited to text, graphics, logos, and images, is owned or licensed by Huppy People (Website/App) and is protected by copyright, trademark, and other intellectual property laws.</p>
                
                <h3>8. Termination</h3>
                <p>We reserve the right to terminate or suspend your access to our services at any time and for any reason, without prior notice or liability.</p>
                
                <h3>9. Disclaimer of Warranties</h3>
                <p>Our services are provided on an "as is" and "as available" basis, without any warranties of any kind, either express or implied. We do not guarantee the accuracy, completeness, or reliability of any content or information on our website.</p>
                
                <h3>10. Limitation of Liability</h3>
                <p>In no event shall Huppy People be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our services.</p>
                
                <h3>11. Indemnification</h3>
                <p>You agree to indemnify and hold Huppy People harmless from any claims, losses, liabilities, damages, costs, or expenses, including legal fees, arising out of or in connection with your use of our services or your violation of these terms and conditions.</p>
                
                <h3>12. Governing Law</h3>
                <p>These terms and conditions shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.</p>
                
                <h3>13. Changes to Terms</h3>
                <p>We reserve the right to update or modify these terms and conditions at any time without prior notice. By continuing to use our services after any such changes, you agree to be bound by the revised terms and conditions.</p>
                
                <h3>14. Contact Us</h3>
                <p>If you have any questions or concerns about these terms and conditions, please contact us at <a href="mailto:assist@huppypeople.com">assist@huppypeople.com</a></p>
            </div>
        </div>
    );
};

export default TermsAndConditions;
