export const countiesWithConstituencies = [
    {
        county: 'Mombasa',
        constituencies: ['Changamwe', 'Jomvu', 'Kisauni', 'Nyali', 'Likoni', 'Mvita']
    },
    {
        county: 'Kwale',
        constituencies: ['Msambweni', 'Lunga Lunga', 'Matuga', 'Kinango']
    },
    {
        county: 'Kilifi',
        constituencies: ['Kilifi North', 'Kilifi South', 'Kaloleni', 'Rabai', 'Ganze', 'Malindi', 'Magarini']
    },
    {
        county: 'Tana River',
        constituencies: ['Garsen', 'Galole', 'Bura']
    },
    {
        county: 'Lamu',
        constituencies: ['Lamu East', 'Lamu West']
    },
    {
        county: 'Taita Taveta',
        constituencies: ['Taveta', 'Wundanyi', 'Mwatate', 'Voi']
    },
    {
        county: 'Garissa',
        constituencies: ['Garissa Township', 'Balambala', 'Lagdera', 'Dadaab', 'Fafi', 'Ijara']
    },
    {
        county: 'Wajir',
        constituencies: ['Wajir North', 'Wajir East', 'Tarbaj', 'Wajir West', 'Eldas', 'Wajir South']
    },
    {
        county: 'Mandera',
        constituencies: ['Mandera West', 'Banissa', 'Mandera North', 'Mandera South', 'Mandera East', 'Lafey']
    },
    {
        county: 'Marsabit',
        constituencies: ['Moyale', 'North Horr', 'Saku', 'Laisamis']
    },
    {
        county: 'Isiolo',
        constituencies: ['Isiolo North', 'Isiolo South']
    },
    {
        county: 'Meru',
        constituencies: ['Igembe South', 'Igembe Central', 'Igembe North', 'Tigania West', 'Tigania East', 'North Imenti', 'Buuri', 'Central Imenti', 'South Imenti']
    },
    {
        county: 'Tharaka Nithi',
        constituencies: ['Maara', 'Chuka/Igambang’ombe', 'Tharaka']
    },
    {
        county: 'Embu',
        constituencies: ['Manyatta', 'Runyenjes', 'Mbeere South', 'Mbeere North']
    },
    {
        county: 'Kitui',
        constituencies: ['Mwingi North', 'Mwingi West', 'Mwingi Central', 'Kitui West', 'Kitui Rural', 'Kitui Central', 'Kitui East', 'Kitui South']
    },
    {
        county: 'Machakos',
        constituencies: ['Masinga', 'Yatta', 'Kangundo', 'Matungulu', 'Kathiani', 'Mavoko', 'Machakos Town', 'Mwala']
    },
    {
        county: 'Makueni',
        constituencies: ['Mbooni', 'Kilome', 'Kaiti', 'Makueni', 'Kibwezi West', 'Kibwezi East']
    },
    {
        county: 'Nyandarua',
        constituencies: ['Kinangop', 'Kipipiri', 'Ol Kalou', 'Ndaragwa', 'Ol Jorok']
    },
    {
        county: 'Nyeri',
        constituencies: ['Tetu', 'Kieni', 'Mathira', 'Othaya', 'Mukureini', 'Nyeri Town']
    },
    {
        county: 'Kirinyaga',
        constituencies: ['Mwea', 'Gichugu', 'Ndia', 'Kirinyaga Central']
    },
    {
        county: 'Murang\'a',
        constituencies: ['Kangema', 'Mathioya', 'Kiharu', 'Kigumo', 'Maragwa', 'Kandara', 'Gatanga']
    },
    {
        county: 'Kiambu',
        constituencies: ['Gatundu South', 'Gatundu North', 'Juja', 'Thika Town', 'Ruiru', 'Githunguri', 'Kiambu', 'Kiambaa', 'Kabete', 'Kikuyu', 'Limuru', 'Lari']
    },
    {
        county: 'Turkana',
        constituencies: ['Turkana North', 'Turkana West', 'Turkana Central', 'Loima', 'Turkana South', 'Turkana East']
    },
    {
        county: 'West Pokot',
        constituencies: ['Kapenguria', 'Sigor', 'Kacheliba', 'Pokot South']
    },
    {
        county: 'Samburu',
        constituencies: ['Samburu West', 'Samburu North', 'Samburu East']
    },
    {
        county: 'Trans Nzoia',
        constituencies: ['Kwanza', 'Endebess', 'Saboti', 'Kiminini', 'Cherangany']
    },
    {
        county: 'Uasin Gishu',
        constituencies: ['Soy', 'Turbo', 'Moiben', 'Ainabkoi', 'Kapseret', 'Kesses']
    },
    {
        county: 'Elgeyo Marakwet',
        constituencies: ['Marakwet East', 'Marakwet West', 'Keiyo North', 'Keiyo South']
    },
    {
        county: 'Nandi',
        constituencies: ['Tinderet', 'Aldai', 'Nandi Hills', 'Chesumei', 'Emgwen', 'Mosop']
    },
    {
        county: 'Baringo',
        constituencies: ['Tiaty', 'Baringo North', 'Baringo Central', 'Baringo South', 'Mogotio', 'Eldama Ravine']
    },
    {
        county: 'Laikipia',
        constituencies: ['Laikipia West', 'Laikipia East', 'Laikipia North']
    },
    {
        county: 'Nakuru',
        constituencies: ['Naivasha', 'Gilgil', 'Nakuru Town West', 'Nakuru Town East', 'Subukia', 'Rongai', 'Bahati', 'Njoro', 'Molo', 'Kuresoi North', 'Kuresoi South']
    },
    {
        county: 'Narok',
        constituencies: ['Kilgoris', 'Emurua Dikirr', 'Narok North', 'Narok East', 'Narok South', 'Narok West']
    },
    {
        county: 'Kajiado',
        constituencies: ['Kajiado North', 'Kajiado Central', 'Kajiado East', 'Kajiado West', 'Kajiado South']
    },
    {
        county: 'Kericho',
        constituencies: ['Kipkelion East', 'Kipkelion West', 'Ainamoi', 'Bureti', 'Belgut', 'Soin Sigowet']
    },
    {
        county: 'Bomet',
        constituencies: ['Sotik', 'Chepalungu', 'Bomet East', 'Bomet Central', 'Konoin']
    },
    {
        county: 'Kakamega',
        constituencies: ['Lugari', 'Likuyani', 'Malava', 'Lurambi', 'Navakholo', 'Mumias West', 'Mumias East', 'Matungu', 'Butere', 'Khwisero', 'Shinyalu', 'Ikolomani']
    },
    {
        county: 'Vihiga',
        constituencies: ['Vihiga', 'Sabatia', 'Hamisi', 'Luanda', 'Emuhaya']
    },
    {
        county: 'Bungoma',
        constituencies: ['Mt. Elgon', 'Sirisia', 'Kabuchai', 'Bumula', 'Kanduyi', 'Webuye East', 'Webuye West', 'Kimilili', 'Tongaren']
    },
    {
        county: 'Busia',
        constituencies: ['Teso North', 'Teso South', 'Nambale', 'Matayos', 'Butula', 'Funyula', 'Budalangi']
    },
    {
        county: 'Siaya',
        constituencies: ['Ugenya', 'Ugunja', 'Alego Usonga', 'Gem', 'Bondo', 'Rarieda']
    },
    {
        county: 'Kisumu',
        constituencies: ['Kisumu East', 'Kisumu West', 'Kisumu Central', 'Seme', 'Nyando', 'Muhoroni', 'Nyakach']
    },
    {
        county: 'Homa Bay',
        constituencies: ['Kasipul', 'Kabondo Kasipul', 'Karachuonyo', 'Rangwe', 'Homa Bay Town', 'Ndhiwa', 'Mbita', 'Suba']
    },
    {
        county: 'Migori',
        constituencies: ['Rongo', 'Awendo', 'Suna East', 'Suna West', 'Uriri', 'Nyatike', 'Kuria West', 'Kuria East']
    },
    {
        county: 'Kisii',
        constituencies: ['Bonchari', 'South Mugirango', 'Bobasi', 'Bomachoge Borabu', 'Bomachoge Chache', 'Nyaribari Masaba', 'Nyaribari Chache', 'Kitutu Chache North', 'Kitutu Chache South']
    },
    {
        county: 'Nyamira',
        constituencies: ['Kitutu Masaba', 'West Mugirango', 'North Mugirango', 'Borabu']
    },
    {
        county: 'Nairobi',
        constituencies: ['Westlands', 'Dagoretti North', 'Dagoretti South', 'Langata', 'Kibra', 'Roysambu', 'Kasarani', 'Ruaraka', 'Embakasi South', 'Embakasi North', 'Embakasi Central', 'Embakasi East', 'Embakasi West', 'Makadara', 'Kamukunji', 'Starehe', 'Mathare']
    }
];

export const genderOptions = ['Male', 'Female'];


export const ageGroupOptions = ['21-27', '28-35', '36-50', '50 and above'];

export const complexionOptions = {
    Male: ['Light', 'Brown', 'Olive', 'Dark', 'Fair', 'Medium', 'Tan'],
    Female: ['YellowYellow', 'Dark', 'Lightskin', 'Chocolate', 'Fair', 'Medium', 'Tan', 'Olive']
};

export const bodyShapeOptions = {
    Male: ['Athletic', 'Muscular', 'Slim', 'Average', 'Heavyset', 'Stocky'],
    Female: ['Slim', 'Curvy', 'Average', 'Chubby', 'Petite', 'Athletic', 'Hourglass', 'Plus Size']
};

export const heightOptions = {
    Male: ['Tall', 'Short', 'Average', 'Very Tall', 'Very Short'],
    Female: ['Tall', 'Short', 'Average', 'Very Tall', 'Very Short']
};

export const countryOptions = [
    'Afghanistan', 'Albania', 'Algeria', 'American', 'Andorra', 'Angola', 'Antigua', 
    'Argentina', 'Armenia', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 
    'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin', 'Bhutan', 'Bolivia',
     'Bosnia', 'Botswana', 'Brazil', 'Britain', 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burma', 
     'Burundi', 'Cabo Verde', 'Cambodia', 'Cameroon', 'Canada', 'Central African Republic', 
     'Chad', 'Chile', 'China', 'Colombia', 'Comoros', 'Congo', 'Costa Rica', 'Croatia', 'Cuba', 
     'Cyprus', 'Czech Republic', 'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic', 'Ecuador', 
     'Egypt', 'El Salvador', 'Equatorial Guinea', 'Eritrea', 'Estonia', 'Ethiopia', 'Fiji', 'Finland',
      'France', 'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana', 'Greece', 'Grenada', 'Guatemala', 
      'Guinea', 'Guinea-Bissau', 'Guyana', 'Haiti', 'Honduras', 'Hungary', 'Iceland', 'India', 
      'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Israel', 'Italy', 'Ivory Coast', 'Jamaica', 
      'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Kuwait', 'Kyrgyzstan', 'Laos',
       'Latvia', 'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 
       'Luxembourg', 'Macedonia', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mali', 
       'Malta', 'Marshall Islands', 'Mauritania', 'Mauritius', 'Mexico', 'Micronesia',
        'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Morocco', 'Mozambique', 'Namibia', 'Nauru', 
        'Nepal', 'Netherlands', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'North Korea', 'Norway',
        'Oman', 'Pakistan', 'Palau', 'Palestine', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 
        'Philippines', 'Poland', 'Portugal', 'Qatar', 'Romania', 'Russia', 'Rwanda', 
        'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Vincent and the Grenadines',
        'Samoa', 'San Marino', 'Sao Tome and Principe', 'Saudi Arabia', 'Senegal', 
        'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia', 
        'Solomon Islands', 'Somalia', 'South Africa', 'South Korea', 'South Sudan', 
        'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Swaziland', 'Sweden', 'Switzerland',
        'Syria', 'Taiwan', 'Tajikistan', 'Tanzania', 'Thailand', 'Timor-Leste', 'Togo', 
        'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Tuvalu',
        'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom', 'United States', 
        'Uruguay', 'Uzbekistan', 'Vanuatu', 'Venezuela', 'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe'
];

export const sexualityOptions = ['Heterosexual', 'Bisexual', 'Gay', 'Lesbian', 'Pansexual', 'Asexual', 'Queer', 'Others'];

export const reasonOptions = ['Fun/Hanging out/Roadtrip', 'Serious relationship leading to marriage', 'We will see where it goes', 'Casual dating', 'Friendship'];

export const ethnicityOptions = [
    'African', 'Caucasian', 'Asian', 'Hispanic', 'Middle Eastern', 'Pacific Islander', 'Native American', 'Mixed', 'Others'
];

export const religionOptions = [
    'Christianity', 'Islam', 'Hinduism', 'Buddhism', 'Sikhism', 'Judaism', 'Baháʼí', 'Jainism', 'Shinto', 'Taoism', 'Zoroastrianism', 'Agnostic', 'Atheist', 'Others'
];


